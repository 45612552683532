/*----- breadcrumb style css start -----*/
.breadcrumb-area {
    
}
.breadcrumb-wrap {
    padding: 95px 0 100px;
    text-align: center;
    // responsive
    @media #{$sm-device} {
        padding: 35px 0;
    }
    .breadcrumb {
        background: transparent;
        margin-bottom: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .breadcrumb-item {
            line-height: 1;
            a {
                color: $white;
                font-size: 14px;
                font-weight: 400;
                text-transform: capitalize;
                &:hover {
                    color: $theme-color;
                }
            }
            &:before {
                color: #7e7e7e;
                content: "/";
                font-size: 12px;
                margin: 0 5px;
            }
            &:first-child {
                &::before {
                    display: none;
                }
            }
            &.active {
                color: $theme-color;
                font-size: 14px;
                font-weight: 400;
                margin-top: 2px;
                text-transform: capitalize;
            }
        }
    }
}
.breadcrumb-title {
    color: $white;
    font-size: 30px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 60px;
    position: relative;
    // responsive
    @media #{$xs-device} {
        font-size: 24px;
    }
    &:before {
        bottom: -37px;
        left: 0;
        right: 0;
        margin: 0 auto;
        position: absolute;
        content: url(../../assets/img/icon/shape.png);
    }
}
/*----- breadcrumb style css end -----*/