/*------ service area start ------*/
.service-carousel-active {
    .slick-list {
        margin: -30px 0;
    }
}
.service {
    &-item {
        padding: 55px 30px;
        text-align: center;
        border-radius: 5px;
        transition: 0.4s;
        box-shadow: 0px 0px 12px -2px rgba(1, 26, 72, 0.1);
        // responsive
        @media #{$xxs-device} {
            padding: 35px 15px;
        }
        p {
            transition: 0.4s;
        }
        .read-more {
            color: $black-soft;
            display: inline-block;
            font-size: 14px;
            font-weight: 500;
            font-family: $heading-font;
            margin-top: 8px;
            transition: 0.4s;
            i {
                padding-left: 5px;
            }
            &:hover {
                letter-spacing: 1.2px;
            }
        }
        &:hover {
            background-color: $theme-color;
            .service-title,
            .read-more,
            p {
                color: $white;
            }
        }
        &__style-2 {
            box-shadow: none;
            border: 1px solid #f1f1f1;
            &:hover {
                background-color: $white;
                box-shadow: 0px 0px 12px -2px rgba(1, 26, 72, 0.1);
                p {
                    color: $paragraph;
                }
                .service-title,
                .read-more {
                    color: $theme-color;
                }
            }
        }
    }
    &-icon {
        padding-bottom: 38px;
        img {
            margin: auto;
        }
    }
    &-title {
        cursor: pointer;
        line-height: 1;
        padding-bottom: 23px;
        transition: 0.4s;
    }
}
.service-title {
    transition: 0.4s;
}
/*------ service area end ------*/

/*------- service details inner start -------*/
.service-details-inner {
    @media #{$md-device, $sm-device} {
        padding-top: 50px;
    }
    h6 {
        font-size: 16px;
        display: inline-block;
        font-weight: 500;
        padding-bottom: 15px;
    }
    h4 {
        font-weight: 500;
        line-height: 1.5;
        padding-bottom: 22px;
    }
    p {
        margin-bottom: 34px;
    }
}
.service-rating {
    color: $theme-color;
}
/*------- service details inner end -------*/