/*------- about us area start -------*/
.about-address {
    margin-top: 52px;

    // responsive
    @media #{$md-device, $sm-device} {
        margin-top: 35px;
    }

    li {
        display: inline-block;

        a {
            color: $black-soft;
            padding-left: 15px;

            &:hover {
                color: $theme-color;
            }
        }

        &.phone,
        &.mail {
            i {
                width: 42px;
                height: 42px;
                font-size: 18px;
                line-height: 42px;
                text-align: center;
                color: $white;
                background-color: $theme-color;
                border-radius: 50%;
            }
        }

        &.mail {
            margin-left: 25px;

            // responsive
            @media #{$xxs-device} {
                margin-left: 0;
                margin-top: 15px;
            }

            i {
                background-color: #152545;
            }
        }
    }
}

.about-thumb {
    text-align: right;
    background-color: #f3f5f6;
    // responsive
    @media #{$md-device, $sm-device} {
        margin-top: 62px;
        text-align: center;
    }
}

/*------- about us area end -------*/

/*------- about-1 area start -------*/
.about1-wrapper {
    margin-bottom: 55px;
    // responsive
    @media #{$sm-device} {
        margin-bottom: 32px;
    }
}
.about1-content {
    h6 {
        color: $theme-color;
        margin-bottom: 18px;
    }

    p {
        color: $paragraph;
        font-weight: 500;
        margin-bottom: 0;
    }
}

.about1-info {
    padding-left: 78px;
    margin-top: -3px;
    margin-bottom: -5px;
    // responsive
    @media #{$md-device, $sm-device} {
        padding-left: 0;
    }
    p {
        margin-bottom: 0;
    }
}

/*------- about-1 area end -------*/

/*----- video popup css start -----*/
.about-thumb__style-2 {
    position: relative;
    &:before {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        background-color: rgba(0,0,0,0.5);
        pointer-events: none;
    }
}
.play-btn {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    line-height: 60px;
    text-align: center;
    background-color: $white;
    color: $theme-color;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease 0s;
}
.waves-block .waves {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    height: 250px;
    left: 50%;
    opacity: 0;
    margin-left: -125px;
    margin-top: -125px;
    position: absolute;
    top: 50%;
    width: 250px;
    }
.waves {
    animation-delay: 0;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-name: waves;
    animation-play-state: running;
    animation-timing-function: linear;
}

.wave-1 {
    animation-delay: 1s;
}

.wave-2 {
    animation-delay: 2s;
}
.wave-3 {
    animation-delay: 3s;
}

@keyframes waves {
    0% {
        transform: scale(0.2, 0.2);
        opacity: 0;
    }
    50% {
        opacity: 0.9;
    }
    100% {
        transform: scale(0.9, 0.9);
        opacity: 0;
    }
}
.mfp-bg {
        opacity: 0.9;
}
.mfp-iframe-holder {
    .mfp-close {
        width: 60px;
        height: 60px;
        font-size: 30px;
        line-height: 60px;
        text-align: center;
        display: block;
        background-color: $theme-color;
        top: -60px;
        right: 0;
        opacity: 1;
    }
}
/*----- video popup css end -----*/
