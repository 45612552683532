/*------ team area style css start ------*/
.team {
    &-member {
        text-align: center;
        &:hover {
            .team-img {
                img {
                    transform: translateY(-15px);
                }
                .team-text {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    &-img {
        position: relative;
    }
    &-text {
        background: $theme-color;
        bottom: 0;
        left: 0;
        padding: 30px 40px 34px;
        position: absolute;
        right: 0;
        text-align: center;
        transition: .4s;
        opacity: 0;
        visibility: hidden;
        // responsive
        @media #{$md-device, $sm-device} {
            padding: 15px 12px 12px;
        }
        p {
            color: $white;
            font-size: 14px;
        }
    }
    &-content {
        padding-top: 20px;
        .member-name {
            font-weight: 500;
        }
        .designation {
            color: $paragraph;
            display: block;
            line-height: 1;
            padding-top: 10px;
        }
    }
    &-social {
        a {
            color: $white;
            font-size: 20px;
            margin-right: 20px;
    
            &:hover {
                color: $black-soft;
            }
    
            &:last-child {
                margin-right: 0;
            }
        }
    }
}



    /*------ team area style css end ------*/