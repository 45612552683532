
/*------- short classes start -------*/
.section-padding {
    padding: 110px 0;
    // responsive
    @media #{$sm-device} {
        padding: 63px 0;
    }
}
.mb-30 {
    margin-bottom: 30px;
}
.mt-20 {
    margin-top: 20px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mt-30 {
    margin-top: 30px;
}
.mt-40 {
    margin-top: 40px;
}
.mt-100 {
    margin-top: 100px;
}
.mtn-20 {
    margin-top: -20px;
}
.mbn-30 {
    margin-bottom: -30px;
}
.mtn-30 {
    margin-top: -30px;
}
.mtb-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}
.mtn-40 {
    margin-top: -40px;
}
.ptb-30 {
    padding: 30px 0;
}
.mtn-100 {
    margin-top: -100px;
}
.mtsm-0 {
    @media #{$sm-device} {
        margin-top: 0;
    }
}
/*------- short classes end -------*/
