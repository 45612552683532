/*------------------------------------------------------------------
Table of content
-------------------
1. Start Header Area
2. slider area start
3. features area css start
4. hot deals area css start
5. product
6. categories area
7. testimonial
8. blog
9. shop grid view
10. shop list view
11. product details
12. checkout
13. cart
14. wishlist
15. my account
16. compare
17. login register
18. about us
19. contact us
20. banner
21. modal
22. sidebar
23. footer
-------------------------------------------------------------------*/
/*------ Typography Style Start ------*/
body {
  color: #555555;
  line-height: 1.7;
  font-size: 16px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}

a {
  transition: 0.4s;
}

a:hover, a:focus {
  outline: none;
  text-decoration: none;
}

p {
  margin-bottom: 8px;
}

p:last-child {
  margin-bottom: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
  color: #222222;
  font-weight: 600;
  margin: 0;
  line-height: 1.2;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0;
  line-height: 1.5;
}

h1, .h1 {
  font-size: 36px;
}

h2, .h2 {
  font-size: 30px;
}

h3, .h3 {
  font-size: 24px;
}

h4, .h4 {
  font-size: 20px;
}

h5, .h5 {
  font-size: 18px;
}

h6, .h6 {
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

strong,
b {
  font-weight: 700;
}

address {
  margin-bottom: 0;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
  transition: 0.4s;
}

.bg-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.btn,
button {
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  box-shadow: none;
  transition: 0.4s;
  background-color: transparent;
}

.btn:active, .btn:focus,
button:active,
button:focus {
  outline: none;
  box-shadow: none;
}

input,
textarea {
  resize: none;
}

input:focus,
textarea:focus {
  outline: none;
}

.form-control:focus {
  border-color: #F7941D;
  box-shadow: none;
}

::-moz-selection {
  color: #fff;
  background: #F7941D;
}

::selection {
  color: #fff;
  background: #F7941D;
}

::placeholder {
  color: #999;
  font-size: 13px;
  opacity: 1;
}

/* ----tab problem fix css ----*/
.tab-content .tab-pane {
  display: block;
  height: 0;
  max-width: 100%;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
}

.tab-content .tab-pane.active {
  height: auto;
  opacity: 1;
  overflow: visible;
  visibility: visible;
}

/* ----tab problem fix css ----*/
/* ---- Sick Slider arrow style start ----*/
.slick-slider .slick-slide > div > div {
  vertical-align: middle;
}

.slick-arrow-style button.slick-arrow {
  top: 50%;
  left: 30px;
  width: 40px;
  height: 40px;
  font-size: 25px;
  border-radius: 50%;
  color: #222222;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  position: absolute;
  transform: translateY(-50%);
  line-height: 42px;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
}

.slick-arrow-style button.slick-arrow i {
  display: block;
  padding-left: 2px;
}

.slick-arrow-style button.slick-arrow.slick-next {
  left: auto;
  right: 30px;
}

.slick-arrow-style button.slick-arrow:hover {
  color: #fff;
  border-color: #F7941D;
  background-color: #F7941D;
}

.slick-arrow-style:hover button.slick-arrow {
  opacity: 1;
  visibility: visible;
  transform: translateY(-50%);
  z-index: 1;
}

.slick-arrow-style:hover button.slick-arrow.slick-prev {
  left: 10px;
}

.slick-arrow-style:hover button.slick-arrow.slick-next {
  right: 10px;
}

.slick-arrow-style_hero button.slick-arrow {
  font-size: 30px;
  left: 0;
  width: 50px;
  height: 50px;
  background-color: #f6f6f6;
  border-radius: 50%;
  line-height: 50px;
  z-index: 1;
}

.slick-arrow-style_hero button.slick-arrow.slick-next {
  right: 0;
}

.slick-arrow-style_hero button.slick-arrow:hover {
  color: #fff;
  background-color: #F7941D;
}

.slick-arrow-style_hero:hover button.slick-arrow.slick-next {
  right: 15px;
}

.slick-arrow-style_hero:hover button.slick-arrow.slick-prev {
  left: 15px;
}

/*--------- slick slider dot style start -------*/
.slick-dot-style ul.slick-dots {
  bottom: 15px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.slick-dot-style ul.slick-dots li {
  display: inline-block;
  margin-right: 10px;
}

.slick-dot-style ul.slick-dots li:last-child {
  margin-right: 0;
}

.slick-dot-style ul.slick-dots li button {
  width: 14px;
  height: 14px;
  padding: 0;
  border: none;
  display: block;
  text-indent: -5000px;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.4s;
  border: 2px solid #777777;
  background-color: transparent;
}

.slick-dot-style ul.slick-dots li.slick-active button {
  border-color: #F7941D;
  background-color: #F7941D;
}

.slick-row-3 .slick-list {
  margin: 0 -3px;
}

.slick-row-3 .slick-list .slick-slide {
  margin: 0 3px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-3 .slick-list {
    margin: 0 -3px !important;
  }
  .slick-sm-row-3 .slick-list .slick-slide {
    margin: 0 3px !important;
  }
}

.slick-row-4 .slick-list {
  margin: 0 -4px;
}

.slick-row-4 .slick-list .slick-slide {
  margin: 0 4px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-4 .slick-list {
    margin: 0 -4px !important;
  }
  .slick-sm-row-4 .slick-list .slick-slide {
    margin: 0 4px !important;
  }
}

.slick-row-5 .slick-list {
  margin: 0 -5px;
}

.slick-row-5 .slick-list .slick-slide {
  margin: 0 5px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-5 .slick-list {
    margin: 0 -5px !important;
  }
  .slick-sm-row-5 .slick-list .slick-slide {
    margin: 0 5px !important;
  }
}

.slick-row-6 .slick-list {
  margin: 0 -6px;
}

.slick-row-6 .slick-list .slick-slide {
  margin: 0 6px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-6 .slick-list {
    margin: 0 -6px !important;
  }
  .slick-sm-row-6 .slick-list .slick-slide {
    margin: 0 6px !important;
  }
}

.slick-row-7 .slick-list {
  margin: 0 -7px;
}

.slick-row-7 .slick-list .slick-slide {
  margin: 0 7px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-7 .slick-list {
    margin: 0 -7px !important;
  }
  .slick-sm-row-7 .slick-list .slick-slide {
    margin: 0 7px !important;
  }
}

.slick-row-8 .slick-list {
  margin: 0 -8px;
}

.slick-row-8 .slick-list .slick-slide {
  margin: 0 8px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-8 .slick-list {
    margin: 0 -8px !important;
  }
  .slick-sm-row-8 .slick-list .slick-slide {
    margin: 0 8px !important;
  }
}

.slick-row-9 .slick-list {
  margin: 0 -9px;
}

.slick-row-9 .slick-list .slick-slide {
  margin: 0 9px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-9 .slick-list {
    margin: 0 -9px !important;
  }
  .slick-sm-row-9 .slick-list .slick-slide {
    margin: 0 9px !important;
  }
}

.slick-row-10 .slick-list {
  margin: 0 -10px;
}

.slick-row-10 .slick-list .slick-slide {
  margin: 0 10px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-10 .slick-list {
    margin: 0 -10px !important;
  }
  .slick-sm-row-10 .slick-list .slick-slide {
    margin: 0 10px !important;
  }
}

.slick-row-11 .slick-list {
  margin: 0 -11px;
}

.slick-row-11 .slick-list .slick-slide {
  margin: 0 11px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-11 .slick-list {
    margin: 0 -11px !important;
  }
  .slick-sm-row-11 .slick-list .slick-slide {
    margin: 0 11px !important;
  }
}

.slick-row-12 .slick-list {
  margin: 0 -12px;
}

.slick-row-12 .slick-list .slick-slide {
  margin: 0 12px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-12 .slick-list {
    margin: 0 -12px !important;
  }
  .slick-sm-row-12 .slick-list .slick-slide {
    margin: 0 12px !important;
  }
}

.slick-row-13 .slick-list {
  margin: 0 -13px;
}

.slick-row-13 .slick-list .slick-slide {
  margin: 0 13px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-13 .slick-list {
    margin: 0 -13px !important;
  }
  .slick-sm-row-13 .slick-list .slick-slide {
    margin: 0 13px !important;
  }
}

.slick-row-14 .slick-list {
  margin: 0 -14px;
}

.slick-row-14 .slick-list .slick-slide {
  margin: 0 14px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-14 .slick-list {
    margin: 0 -14px !important;
  }
  .slick-sm-row-14 .slick-list .slick-slide {
    margin: 0 14px !important;
  }
}

.slick-row-15 .slick-list {
  margin: 0 -15px;
}

.slick-row-15 .slick-list .slick-slide {
  margin: 0 15px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-15 .slick-list {
    margin: 0 -15px !important;
  }
  .slick-sm-row-15 .slick-list .slick-slide {
    margin: 0 15px !important;
  }
}

.slick-slider .slick-slide > div {
  margin-bottom: 30px;
}

.slick-slider .slick-slide > div:last-child {
  margin-bottom: 0;
}

/* ----scroll to top css start ----*/
.scroll-top {
  bottom: 50px;
  cursor: pointer;
  height: 50px;
  position: fixed;
  right: 20px;
  text-align: center;
  width: 50px;
  z-index: 9999;
  transition: 0.4s;
  border-radius: 50%;
  background-color: #F7941D;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 767.98px) {
  .scroll-top {
    display: none;
  }
}

.scroll-top i {
  line-height: 50px;
  color: #fff;
  font-size: 25px;
}

.scroll-top.not-visible {
  bottom: -50px;
  visibility: hidden;
  opacity: 0;
}

.scroll-top:hover {
  background-color: #222222;
}

/* ----scroll to top css end ----*/
.row-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.row-0 > [class*="col"] {
  padding-left: 0px;
  padding-right: 0px;
}

.row-1 {
  margin-left: -0.5px;
  margin-right: -0.5px;
}

.row-1 > [class*="col"] {
  padding-left: 0.5px;
  padding-right: 0.5px;
}

.row-2 {
  margin-left: -1px;
  margin-right: -1px;
}

.row-2 > [class*="col"] {
  padding-left: 1px;
  padding-right: 1px;
}

.row-3 {
  margin-left: -1.5px;
  margin-right: -1.5px;
}

.row-3 > [class*="col"] {
  padding-left: 1.5px;
  padding-right: 1.5px;
}

.row-4 {
  margin-left: -2px;
  margin-right: -2px;
}

.row-4 > [class*="col"] {
  padding-left: 2px;
  padding-right: 2px;
}

.row-5 {
  margin-left: -2.5px;
  margin-right: -2.5px;
}

.row-5 > [class*="col"] {
  padding-left: 2.5px;
  padding-right: 2.5px;
}

.row-6 {
  margin-left: -3px;
  margin-right: -3px;
}

.row-6 > [class*="col"] {
  padding-left: 3px;
  padding-right: 3px;
}

.row-7 {
  margin-left: -3.5px;
  margin-right: -3.5px;
}

.row-7 > [class*="col"] {
  padding-left: 3.5px;
  padding-right: 3.5px;
}

.row-8 {
  margin-left: -4px;
  margin-right: -4px;
}

.row-8 > [class*="col"] {
  padding-left: 4px;
  padding-right: 4px;
}

.row-9 {
  margin-left: -4.5px;
  margin-right: -4.5px;
}

.row-9 > [class*="col"] {
  padding-left: 4.5px;
  padding-right: 4.5px;
}

.row-10 {
  margin-left: -5px;
  margin-right: -5px;
}

.row-10 > [class*="col"] {
  padding-left: 5px;
  padding-right: 5px;
}

.row-11 {
  margin-left: -5.5px;
  margin-right: -5.5px;
}

.row-11 > [class*="col"] {
  padding-left: 5.5px;
  padding-right: 5.5px;
}

.row-12 {
  margin-left: -6px;
  margin-right: -6px;
}

.row-12 > [class*="col"] {
  padding-left: 6px;
  padding-right: 6px;
}

.row-13 {
  margin-left: -6.5px;
  margin-right: -6.5px;
}

.row-13 > [class*="col"] {
  padding-left: 6.5px;
  padding-right: 6.5px;
}

.row-14 {
  margin-left: -7px;
  margin-right: -7px;
}

.row-14 > [class*="col"] {
  padding-left: 7px;
  padding-right: 7px;
}

.row-15 {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.row-15 > [class*="col"] {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.row-16 {
  margin-left: -8px;
  margin-right: -8px;
}

.row-16 > [class*="col"] {
  padding-left: 8px;
  padding-right: 8px;
}

.row-17 {
  margin-left: -8.5px;
  margin-right: -8.5px;
}

.row-17 > [class*="col"] {
  padding-left: 8.5px;
  padding-right: 8.5px;
}

.row-18 {
  margin-left: -9px;
  margin-right: -9px;
}

.row-18 > [class*="col"] {
  padding-left: 9px;
  padding-right: 9px;
}

.row-19 {
  margin-left: -9.5px;
  margin-right: -9.5px;
}

.row-19 > [class*="col"] {
  padding-left: 9.5px;
  padding-right: 9.5px;
}

.row-20 {
  margin-left: -10px;
  margin-right: -10px;
}

.row-20 > [class*="col"] {
  padding-left: 10px;
  padding-right: 10px;
}

.row-21 {
  margin-left: -10.5px;
  margin-right: -10.5px;
}

.row-21 > [class*="col"] {
  padding-left: 10.5px;
  padding-right: 10.5px;
}

.row-22 {
  margin-left: -11px;
  margin-right: -11px;
}

.row-22 > [class*="col"] {
  padding-left: 11px;
  padding-right: 11px;
}

.row-23 {
  margin-left: -11.5px;
  margin-right: -11.5px;
}

.row-23 > [class*="col"] {
  padding-left: 11.5px;
  padding-right: 11.5px;
}

.row-24 {
  margin-left: -12px;
  margin-right: -12px;
}

.row-24 > [class*="col"] {
  padding-left: 12px;
  padding-right: 12px;
}

.row-25 {
  margin-left: -12.5px;
  margin-right: -12.5px;
}

.row-25 > [class*="col"] {
  padding-left: 12.5px;
  padding-right: 12.5px;
}

.row-26 {
  margin-left: -13px;
  margin-right: -13px;
}

.row-26 > [class*="col"] {
  padding-left: 13px;
  padding-right: 13px;
}

.row-27 {
  margin-left: -13.5px;
  margin-right: -13.5px;
}

.row-27 > [class*="col"] {
  padding-left: 13.5px;
  padding-right: 13.5px;
}

.row-28 {
  margin-left: -14px;
  margin-right: -14px;
}

.row-28 > [class*="col"] {
  padding-left: 14px;
  padding-right: 14px;
}

.row-29 {
  margin-left: -14.5px;
  margin-right: -14.5px;
}

.row-29 > [class*="col"] {
  padding-left: 14.5px;
  padding-right: 14.5px;
}

.row-30 {
  margin-left: -15px;
  margin-right: -15px;
}

.row-30 > [class*="col"] {
  padding-left: 15px;
  padding-right: 15px;
}

.container {
  max-width: 1200px;
}

/*------- short classes start -------*/
.section-padding {
  padding: 110px 0;
}

@media only screen and (max-width: 767.98px) {
  .section-padding {
    padding: 63px 0;
  }
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-100 {
  margin-top: 100px;
}

.mtn-20 {
  margin-top: -20px;
}

.mbn-30 {
  margin-bottom: -30px;
}

.mtn-30 {
  margin-top: -30px;
}

.mtb-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mtn-40 {
  margin-top: -40px;
}

.ptb-30 {
  padding: 30px 0;
}

.mtn-100 {
  margin-top: -100px;
}

@media only screen and (max-width: 767.98px) {
  .mtsm-0 {
    margin-top: 0;
  }
}

/*------- short classes end -------*/
/*------- header top style start -------*/
.header-top {
  padding: 15px 0;
}

.header-top-left li {
  color: #fff;
  display: inline-block;
  margin-right: 25px;
  padding-right: 25px;
  position: relative;
}

.header-top-left li:before {
  top: 50%;
  right: 0;
  width: 1px;
  height: 100%;
  content: '';
  position: absolute;
  background-color: #083281;
  transform: translateY(-50%);
}

.header-top-left li a {
  color: #fff;
  font-size: 15px;
  padding-left: 15px;
}

.header-top-left li a:hover {
  color: #F7941D;
}

.header-top-left li:last-child:before {
  display: none;
}

.header-social-link a {
  color: #fff;
  margin-left: 22px;
}

.header-social-link a:hover {
  color: #F7941D;
}

.header-social-link a:first-child {
  margin-left: 0;
}

/*------- header top style end -------*/
/*------ main menu start ------*/
.main-menu ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.main-menu ul li {
  position: relative;
}

.main-menu ul li a {
  color: #222222;
  font-size: 13px;
  padding: 29px 15px;
  font-weight: 500;
  display: block;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}

.main-menu ul li a i {
  font-size: 14px;
  padding: 0 3px;
}

.main-menu ul li:last-child a {
  padding-right: 0;
}

.main-menu ul li:hover > a, .main-menu ul li.active > a {
  color: #F7941D;
}

.main-menu ul li ul.dropdown {
  top: 100%;
  left: 0;
  width: 220px;
  position: absolute;
  transition: 0.4s;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  z-index: 99999;
  pointer-events: none;
  transform: translateY(20px);
  border: 1px solid #efefef;
}

.main-menu ul li ul.dropdown li {
  margin-right: 0;
  border-right: none;
  flex-basis: 100%;
}

.main-menu ul li ul.dropdown li a {
  color: #555555;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 20px;
  text-transform: capitalize;
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  z-index: 1;
}

.main-menu ul li ul.dropdown li a i {
  float: right;
  padding-top: 5px;
}

.main-menu ul li ul.dropdown li:hover > a {
  color: #fff;
  background-color: #F7941D;
}

.main-menu ul li ul.dropdown li:hover > ul.dropdown {
  top: 0;
  opacity: 1;
  visibility: visible;
}

.main-menu ul li ul.dropdown li ul.dropdown {
  top: 100%;
  left: 100%;
  opacity: 0;
  visibility: hidden;
}

.main-menu ul li:hover ul.dropdown {
  opacity: 1;
  visibility: visible;
  pointer-events: visible;
  transform: translate(0);
}

/*------ main menu end ------*/
/*------ sticky menu style start ------*/
.sticky.is-sticky {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 9;
  background-color: #fff;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
  animation: 900ms cubic-bezier(0.2, 1, 0.22, 1) 0s normal none 1 running fadeInDown;
}

/*------ sticky menu style end ------*/
/*----- mobile menu start -----*/
.mobile-header {
  padding: 10px 0;
}

.mobile-header-top {
  border-bottom: 1px solid #e5e5e5;
}

.mobile-header-top .header-top-settings {
  float: none;
}

.mobile-logo {
  max-width: 140px;
  width: 100%;
}

.mobile-main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-main-header .mobile-menu-toggler {
  display: flex;
  align-items: center;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap {
  line-height: 1;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap a {
  font-size: 25px;
  line-height: 1;
  color: #222222;
  position: relative;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap a:hover {
  color: #F7941D;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap .notification {
  font-size: 13px;
  color: #fff;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 50%;
  text-align: center;
  font-weight: 600;
  right: -4px;
  top: -4px;
  position: absolute;
  background-color: #F7941D;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn {
  margin-left: 25px;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn span {
  width: 25px;
  height: 2px;
  display: block;
  transition: 0.4s;
  background-color: #222222;
  transform-origin: left;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn span:nth-child(2), .mobile-main-header .mobile-menu-toggler .mobile-menu-btn span:nth-child(3) {
  margin-top: 5px;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn:hover span {
  background-color: #F7941D;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn:hover span:nth-child(1), .mobile-main-header .mobile-menu-toggler .mobile-menu-btn:hover span:nth-child(3) {
  width: 20px;
}

.mobile-navigation {
  overflow: hidden;
  max-height: 320px;
}

.mobile-navigation nav {
  height: 100%;
}

.mobile-menu {
  margin-top: 30px;
  height: 100%;
  overflow: auto;
  padding-right: 30px;
  margin-right: -30px;
}

@media only screen and (max-width: 479.98px) {
  .mobile-menu {
    margin-top: 15px;
  }
}

.mobile-menu li > a {
  font-size: 15px;
  color: #222222;
  text-transform: capitalize;
  line-height: 18px;
  position: relative;
  display: inline-block;
  padding: 10px 0;
}

.mobile-menu li > a:hover {
  color: #F7941D;
}

.mobile-menu li ul li {
  border: none;
}

.mobile-menu li ul li a {
  font-size: 14px;
  text-transform: capitalize;
  padding: 10px 0 8px;
}

.mobile-menu li.menu-item-has-children {
  display: block;
  position: relative;
}

.mobile-menu li.menu-item-has-children .dropdown {
  padding-left: 15px;
}

.mobile-menu li.menu-item-has-children .menu-expand {
  line-height: 50;
  top: -5px;
  left: 95%;
  width: 30px;
  position: absolute;
  height: 50px;
  text-align: center;
  cursor: pointer;
}

.mobile-menu li.menu-item-has-children .menu-expand i {
  display: block;
  position: relative;
  width: 10px;
  margin-top: 25px;
  border-bottom: 1px solid #222222;
  transition: all 250ms ease-out;
}

.mobile-menu li.menu-item-has-children .menu-expand i:before {
  top: 0;
  width: 100%;
  content: "";
  display: block;
  position: absolute;
  transform: rotate(90deg);
  border-bottom: 1px solid #222222;
  transition: 0.4s;
}

.mobile-menu li.menu-item-has-children.active > .menu-expand i:before {
  transform: rotate(0);
}

/*----- mobile menu end -----*/
/*------ offcanvas widget area start ------*/
.offcanvas-widget-area {
  margin-top: auto;
  padding-bottom: 30px;
}

@media only screen and (max-width: 479.98px) {
  .offcanvas-widget-area {
    padding-bottom: 0;
  }
}

.off-canvas-contact-widget li {
  color: #555555;
  font-size: 15px;
  margin-bottom: 5px;
}

.off-canvas-contact-widget li i {
  width: 20px;
}

.off-canvas-contact-widget li a {
  color: #555555;
}

.off-canvas-contact-widget li a:hover {
  color: #F7941D;
}

.off-canvas-social-widget {
  margin-top: 20px;
}

.off-canvas-social-widget a {
  color: #555555;
  font-size: 18px;
  display: inline-block;
  margin-right: 15px;
}

.off-canvas-social-widget a:hover {
  color: #F7941D;
}

/*------ offcanvas widget area end ------*/
.off-canvas-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  z-index: 9999;
}

.off-canvas-wrapper.open {
  opacity: 1;
  visibility: visible;
}

.off-canvas-wrapper.open .off-canvas-inner-content {
  transform: none;
}

.off-canvas-wrapper .off-canvas-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  content: '';
  cursor: url("../img/icon/cancel.png"), auto;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.off-canvas-wrapper .off-canvas-inner-content {
  background-color: #fff;
  width: 300px;
  padding: 20px;
  height: 100%;
  position: relative;
  transform: translateX(calc(-100% - 50px));
  transition: 0.4s;
}

@media only screen and (max-width: 479.98px) {
  .off-canvas-wrapper .off-canvas-inner-content {
    width: 260px;
    padding: 15px;
  }
}

.off-canvas-wrapper .btn-close-off-canvas {
  top: 0;
  left: 100%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  color: #fff;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  transition: 0.4s;
  background-color: #F7941D;
}

.off-canvas-wrapper .btn-close-off-canvas i {
  font-size: 18px;
  transform: rotate(0);
  transition: 0.4s;
  display: block;
  line-height: 40px;
}

.off-canvas-wrapper .btn-close-off-canvas:hover i {
  transform: rotate(-90deg);
}

.off-canvas-wrapper .off-canvas-inner {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  height: 100%;
}

.search-box-offcanvas form {
  position: relative;
}

.search-box-offcanvas form input {
  color: #666;
  font-size: 13px;
  width: 100%;
  height: 40px;
  border: none;
  padding: 0 40px 0 10px;
  background-color: #f2f2f2;
}

.search-box-offcanvas form .search-btn {
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  line-height: 42px;
  font-size: 20px;
  color: #222222;
  position: absolute;
}

.search-box-offcanvas form .search-btn:hover {
  color: #F7941D;
}

/*------- mobile top bar settings start -------*/
.mobile-settings {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #e5e5e5;
}

.mobile-settings li {
  margin-bottom: 5px;
}

.mobile-settings .nav {
  flex-direction: column;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-toggle {
  font-size: 14px;
  color: #555555;
  cursor: pointer;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-toggle i {
  font-size: 12px;
  padding-left: 5px;
  vertical-align: middle;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-toggle:hover {
  color: #F7941D;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-toggle:after {
  display: none;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-menu {
  padding: 0;
  border-color: #e5e5e5;
  transform: translateY(30px);
  transition: 0.4s;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-menu.show {
  transform: translateY(0);
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-menu .dropdown-item {
  font-size: 13px;
  color: #555555;
  padding: 6px 15px;
}

/*------- mobile top bar settings end -------*/
/*------- Buttons Style here -------*/
.btn {
  color: #fff;
  line-height: 1;
  border-radius: 0;
  font-family: "Montserrat", sans-serif;
}

.btn-hero {
  background-color: #F7941D;
  padding: 20px 34px;
}

@media only screen and (max-width: 767.98px) {
  .btn-hero {
    padding: 15px 25px;
  }
}

.btn-hero:hover {
  color: #fff;
  background-color: #222222;
}

.btn-app {
  height: 70px;
  background-color: #F7941D;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  padding: 0 45px;
}

.btn-app:hover {
  background-color: #222222;
  color: #fff;
}

.btn-theme {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  background-color: #F7941D;
  padding: 18px 30px;
  border-radius: 50px;
}

.btn-theme:hover {
  color: #fff;
  background-color: #222222;
}

/*------ section title start ------*/
.section-title {
  margin-top: -12px;
  margin-bottom: 52px;
}

@media only screen and (max-width: 767.98px) {
  .section-title {
    margin-bottom: 35px;
  }
}

.section-title .title {
  font-size: 48px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .section-title .title {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767.98px) {
  .section-title .title {
    font-size: 24px;
    padding-top: 5px;
  }
}

.section-title .title:before {
  bottom: -36px;
  left: 0;
  right: 0;
  margin: 0 auto;
  content: url(../../assets/img/icon/shape.png);
  position: absolute;
}

.section-title .sub-title {
  padding-top: 36px;
  margin: 0 auto;
  max-width: 55%;
}

@media only screen and (max-width: 767.98px) {
  .section-title .sub-title {
    max-width: 71%;
  }
}

@media only screen and (max-width: 575.98px) {
  .section-title .sub-title {
    max-width: 86%;
  }
}

.section-title-append {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: -4px;
  margin-bottom: 18px;
  line-height: 1;
}

.section-title-append:after {
  top: 50%;
  right: 0;
  width: 100%;
  height: 1px;
  content: "";
  position: absolute;
  background-color: #e5e5e5;
  transform: translateY(-50%);
  z-index: 1;
  margin-top: -3px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .section-title-append:after {
    margin-top: 2px;
  }
}

.section-title-append h4 {
  display: inline-block;
  line-height: 1;
  text-transform: capitalize;
  background-color: #fff;
  position: relative;
  z-index: 2;
  padding-right: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .section-title-append h4 {
    font-size: 16px;
  }
}

/*------ section title end ------*/
/*------- common css start -------*/
.blue-bg {
  background-color: #011a48;
}

.gray-bg {
  background-color: #f3f5f6;
}

/*----- pest control style start -----*/
.pest-item {
  margin-bottom: -3px;
}

.pest-item:hover .pest-icon {
  border-color: #F7941D;
}

.pest-icon {
  width: 110px;
  height: 110px;
  text-align: center;
  line-height: 90px;
  border: 10px solid #fff;
  border-radius: 50%;
  margin: 0 auto;
  cursor: pointer;
  transition: 0.4s;
}

.pest-title {
  margin-top: 18px;
}

.pest-title a {
  color: #5f5f5f;
}

.pest-title a:hover {
  color: #F7941D;
}

/*----- pest control style end -----*/
/*------ counter up start ------*/
.counterup-area {
  padding: 100px 0 105px;
}

@media only screen and (max-width: 767.98px) {
  .counterup-area {
    padding: 52px 0 60px;
  }
}

.counterup-item {
  text-align: center;
}

.counterup-item span {
  color: #F7941D;
  font-size: 48px;
  font-weight: 600;
}

@media only screen and (max-width: 767.98px) {
  .counterup-item span {
    font-size: 36px;
  }
}

.counterup-item h4 {
  font-weight: 400;
  padding-top: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .counterup-item h4 {
    font-size: 16px;
  }
}

.odometer {
  margin-left: -25px;
}

.odometer-inside {
  position: relative;
}

.odometer-inside::after {
  top: 0;
  left: 100%;
  content: '+';
  position: absolute;
}

/*------ counter up end ------*/
/*------- appointment form start -------*/
.appoint-form-wrapper {
  border-radius: 5px;
  padding: 60px;
}

@media only screen and (max-width: 575.98px) {
  .appoint-form-wrapper {
    padding: 60px 15px;
  }
}

.appoint-form-wrapper__style-2 {
  padding: 40px 30px 55px;
  max-width: 408px;
  margin: 0 auto;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .appoint-form-wrapper__style-2 {
    margin-top: 62px;
  }
}

.appoint-form-wrapper__style-2 .appoint-title {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 479.98px) {
  .appoint-form-wrapper__style-2 .appoint-title {
    font-size: 19px;
  }
}

.appoint-form-wrapper__style-2 .btn-app {
  background-color: #fff;
  border-radius: 40px;
  color: #222222;
  height: inherit;
  padding: 14px 30px;
}

.appoint-form-wrapper__style-2 .btn-app:hover {
  color: #fff;
  background-color: #222222;
}

.appoint-title {
  color: #fff;
  margin-top: -5px;
  padding-bottom: 30px;
}

@media only screen and (max-width: 479.98px) {
  .appoint-title {
    font-size: 22px;
  }
}

.appoint-form-inner .appoint-btn {
  padding-left: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .appoint-form-inner .appoint-btn {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .appoint-form-inner .appoint-btn .btn-app {
    display: block;
    width: 100%;
  }
}

.customer-appoint-box {
  background-color: #fff;
  max-width: 840px;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .customer-appoint-box {
    background-color: transparent;
  }
}

.customer-appoint-box .bdr-right {
  border-right: 1px solid #e5e5e5;
}

.customer-appoint-box__style-2 {
  background-color: transparent;
  max-width: inherit;
}

.customer-appoint-box__style-2 .app-txt-field {
  color: #fff;
  width: 100%;
  height: 50px;
  border: 1px solid #fff;
  margin-bottom: 30px;
  background-color: transparent;
  padding: 0 20px;
}

.customer-appoint-box__style-2 ::-webkit-input-placeholder {
  color: #fff;
}

.customer-appoint-box__style-2 ::-moz-placeholder {
  color: #fff;
}

.customer-service-type {
  width: 30%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .customer-service-type {
    width: 100%;
    margin-bottom: 20px;
    float: left;
  }
}

.customer-service-type .nice-select {
  width: 100%;
  height: 70px;
  border: none;
  border-radius: 0;
  line-height: 70px;
}

.customer-service-type .nice-select .list {
  width: 100%;
}

.customer-service-type .nice-select:after {
  height: 8px;
  width: 8px;
  border-bottom: 1px solid #999;
  border-right: 1px solid #999;
}

.customer-name {
  width: 20%;
  height: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .customer-name {
    width: 100%;
    margin-bottom: 20px;
    float: left;
  }
}

.customer-name input {
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  border: none;
}

.customer-phone {
  width: 20%;
  height: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .customer-phone {
    width: 100%;
    margin-bottom: 20px;
    float: left;
  }
}

.customer-phone input {
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  border: none;
}

.customer-email {
  width: 30%;
  height: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .customer-email {
    width: 100%;
    margin-bottom: 20px;
    float: left;
  }
}

.customer-email input {
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  border: none;
}

/*------- appointment form end -------*/
/*------- choose us area start -------*/
.choose-item {
  margin-bottom: -5px;
}

@media only screen and (max-width: 575.98px) {
  .choose-item {
    padding: 0 30px;
  }
}

.choose-text .title {
  padding: 32px 0  24px;
}

/*------- choose us area end -------*/
/*------- common css end -------*/
/*------ hero slider area css start ------*/
/*-------------------------
02. Slider area
--------------------------*/
.hero-slider-item {
  height: 800px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1599.98px) {
  .hero-slider-item {
    height: 650px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px), only screen and (min-width: 1200px) and (max-width: 1499.98px) {
  .hero-slider-item {
    height: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .hero-slider-item {
    height: 400px;
  }
}

@media only screen and (max-width: 575.98px) {
  .hero-slider-item {
    height: 350px;
  }
}

.hero-slider-content .slide-title {
  color: #fff;
  font-size: 50px;
  line-height: 1.2;
  font-family: "Montserrat", sans-serif;
}

.hero-slider-content .slide-title span {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .hero-slider-content .slide-title {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767.98px) {
  .hero-slider-content .slide-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479.98px) {
  .hero-slider-content .slide-title {
    font-size: 25px;
  }
}

.hero-slider-content .slide-desc {
  color: #fff;
  font-weight: 500;
  line-height: 1.3;
  padding-bottom: 16px;
}

.hero-slider-content a {
  margin-top: 32px;
}

.hero-overlay {
  position: relative;
}

.hero-overlay:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}

@media only screen and (max-width: 767.98px) {
  .hero-overlay:before {
    display: block;
  }
}

/*------ hero slider area css end ------*/
/*----- hero video style start -----*/
.hero-video {
  padding-bottom: 43% !important;
}

@media only screen and (max-width: 767.98px) {
  .hero-video {
    padding-bottom: 70% !important;
  }
}

@media only screen and (max-width: 575.98px) {
  .hero-video {
    padding-bottom: 70% !important;
  }
}

@media only screen and (max-width: 479.98px) {
  .hero-video {
    padding-bottom: 100% !important;
  }
}

.hero-slider {
  position: relative;
}

.hero-video-content-wrapper {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

/*----- hero video style end -----*/
/*------- about us area start -------*/
.about-address {
  margin-top: 52px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .about-address {
    margin-top: 35px;
  }
}

.about-address li {
  display: inline-block;
}

.about-address li a {
  color: #222222;
  padding-left: 15px;
}

.about-address li a:hover {
  color: #F7941D;
}

.about-address li.phone i, .about-address li.mail i {
  width: 42px;
  height: 42px;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  color: #fff;
  background-color: #F7941D;
  border-radius: 50%;
}

.about-address li.mail {
  margin-left: 25px;
}

@media only screen and (max-width: 479.98px) {
  .about-address li.mail {
    margin-left: 0;
    margin-top: 15px;
  }
}

.about-address li.mail i {
  background-color: #152545;
}

.about-thumb {
  text-align: right;
  background-color: #f3f5f6;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .about-thumb {
    margin-top: 62px;
    text-align: center;
  }
}

/*------- about us area end -------*/
/*------- about-1 area start -------*/
.about1-wrapper {
  margin-bottom: 55px;
}

@media only screen and (max-width: 767.98px) {
  .about1-wrapper {
    margin-bottom: 32px;
  }
}

.about1-content h6 {
  color: #F7941D;
  margin-bottom: 18px;
}

.about1-content p {
  color: #555555;
  font-weight: 500;
  margin-bottom: 0;
}

.about1-info {
  padding-left: 78px;
  margin-top: -3px;
  margin-bottom: -5px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .about1-info {
    padding-left: 0;
  }
}

.about1-info p {
  margin-bottom: 0;
}

/*------- about-1 area end -------*/
/*----- video popup css start -----*/
.about-thumb__style-2 {
  position: relative;
}

.about-thumb__style-2:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}

.play-btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  line-height: 60px;
  text-align: center;
  background-color: #fff;
  color: #F7941D;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease 0s;
}

.waves-block .waves {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  height: 250px;
  left: 50%;
  opacity: 0;
  margin-left: -125px;
  margin-top: -125px;
  position: absolute;
  top: 50%;
  width: 250px;
}

.waves {
  animation-delay: 0;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: waves;
  animation-play-state: running;
  animation-timing-function: linear;
}

.wave-1 {
  animation-delay: 1s;
}

.wave-2 {
  animation-delay: 2s;
}

.wave-3 {
  animation-delay: 3s;
}

@keyframes waves {
  0% {
    transform: scale(0.2, 0.2);
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    transform: scale(0.9, 0.9);
    opacity: 0;
  }
}

.mfp-bg {
  opacity: 0.9;
}

.mfp-iframe-holder .mfp-close {
  width: 60px;
  height: 60px;
  font-size: 30px;
  line-height: 60px;
  text-align: center;
  display: block;
  background-color: #F7941D;
  top: -60px;
  right: 0;
  opacity: 1;
}

/*----- video popup css end -----*/
/*------ service area start ------*/
.service-carousel-active .slick-list {
  margin: -30px 0;
}

.service-item {
  padding: 55px 30px;
  text-align: center;
  border-radius: 5px;
  transition: 0.4s;
  box-shadow: 0px 0px 12px -2px rgba(1, 26, 72, 0.1);
}

@media only screen and (max-width: 479.98px) {
  .service-item {
    padding: 35px 15px;
  }
}

.service-item p {
  transition: 0.4s;
}

.service-item .read-more {
  color: #222222;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  margin-top: 8px;
  transition: 0.4s;
}

.service-item .read-more i {
  padding-left: 5px;
}

.service-item .read-more:hover {
  letter-spacing: 1.2px;
}

.service-item:hover {
  background-color: #F7941D;
}

.service-item:hover .service-title,
.service-item:hover .read-more,
.service-item:hover p {
  color: #fff;
}

.service-item__style-2 {
  box-shadow: none;
  border: 1px solid #f1f1f1;
}

.service-item__style-2:hover {
  background-color: #fff;
  box-shadow: 0px 0px 12px -2px rgba(1, 26, 72, 0.1);
}

.service-item__style-2:hover p {
  color: #555555;
}

.service-item__style-2:hover .service-title,
.service-item__style-2:hover .read-more {
  color: #F7941D;
}

.service-icon {
  padding-bottom: 38px;
}

.service-icon img {
  margin: auto;
}

.service-title {
  cursor: pointer;
  line-height: 1;
  padding-bottom: 23px;
  transition: 0.4s;
}

.service-title {
  transition: 0.4s;
}

/*------ service area end ------*/
/*------- service details inner start -------*/
@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .service-details-inner {
    padding-top: 50px;
  }
}

.service-details-inner h6 {
  font-size: 16px;
  display: inline-block;
  font-weight: 500;
  padding-bottom: 15px;
}

.service-details-inner h4 {
  font-weight: 500;
  line-height: 1.5;
  padding-bottom: 22px;
}

.service-details-inner p {
  margin-bottom: 34px;
}

.service-rating {
  color: #F7941D;
}

/*------- service details inner end -------*/
/*----- testimonial area start -----*/
.testimonial-thumb-wrapper {
  max-width: 290px;
  margin: auto;
}

.testimonial-thumb {
  cursor: pointer;
  transition: 0.4s;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  width: 90px !important;
  transform: scale(0.7);
}

.slick-slide.slick-current .testimonial-thumb {
  transform: scale(1);
}

.testimonial-content-wrapper {
  max-width: 815px;
  margin: 25px auto -8px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .testimonial-content-wrapper {
    max-width: 750px;
  }
}

.testimonial-content {
  text-align: center;
}

.testimonial-content p {
  color: #222222;
  font-size: 20px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.5;
  margin: 6px 0 30px;
}

@media only screen and (max-width: 767.98px) {
  .testimonial-content p {
    font-size: 18px;
  }
}

.testimonial-content .designation {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #222222;
}

.testimonial-author {
  font-weight: 500;
  line-height: 1;
  margin-bottom: 5px;
  text-transform: uppercase;
}

/*----- testimonial area end -----*/
/*----- breadcrumb style css start -----*/
.breadcrumb-wrap {
  padding: 95px 0 100px;
  text-align: center;
}

@media only screen and (max-width: 767.98px) {
  .breadcrumb-wrap {
    padding: 35px 0;
  }
}

.breadcrumb-wrap .breadcrumb {
  background: transparent;
  margin-bottom: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.breadcrumb-wrap .breadcrumb .breadcrumb-item {
  line-height: 1;
}

.breadcrumb-wrap .breadcrumb .breadcrumb-item a {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

.breadcrumb-wrap .breadcrumb .breadcrumb-item a:hover {
  color: #F7941D;
}

.breadcrumb-wrap .breadcrumb .breadcrumb-item:before {
  color: #7e7e7e;
  content: "/";
  font-size: 12px;
  margin: 0 5px;
}

.breadcrumb-wrap .breadcrumb .breadcrumb-item:first-child::before {
  display: none;
}

.breadcrumb-wrap .breadcrumb .breadcrumb-item.active {
  color: #F7941D;
  font-size: 14px;
  font-weight: 400;
  margin-top: 2px;
  text-transform: capitalize;
}

.breadcrumb-title {
  color: #fff;
  font-size: 30px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 60px;
  position: relative;
}

@media only screen and (max-width: 575.98px) {
  .breadcrumb-title {
    font-size: 24px;
  }
}

.breadcrumb-title:before {
  bottom: -37px;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  content: url(../../assets/img/icon/shape.png);
}

/*----- breadcrumb style css end -----*/
/*------ team area style css start ------*/
.team-member {
  text-align: center;
}

.team-member:hover .team-img img {
  transform: translateY(-15px);
}

.team-member:hover .team-img .team-text {
  opacity: 1;
  visibility: visible;
}

.team-img {
  position: relative;
}

.team-text {
  background: #F7941D;
  bottom: 0;
  left: 0;
  padding: 30px 40px 34px;
  position: absolute;
  right: 0;
  text-align: center;
  transition: .4s;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .team-text {
    padding: 15px 12px 12px;
  }
}

.team-text p {
  color: #fff;
  font-size: 14px;
}

.team-content {
  padding-top: 20px;
}

.team-content .member-name {
  font-weight: 500;
}

.team-content .designation {
  color: #555555;
  display: block;
  line-height: 1;
  padding-top: 10px;
}

.team-social a {
  color: #fff;
  font-size: 20px;
  margin-right: 20px;
}

.team-social a:hover {
  color: #222222;
}

.team-social a:last-child {
  margin-right: 0;
}

/*------ team area style css end ------*/
/*------- pricing table start -------*/
.pricing-item {
  border-radius: 5px;
  padding: 50px 0;
  text-align: center;
  border: 1px solid #e5e5e5;
  transition: 0.4s;
}

.pricing-item:hover, .pricing-item.active {
  box-shadow: 0 0 30px 1px rgba(1, 26, 72, 0.12);
}

.dolar {
  color: #011a48;
  font-size: 26px;
  font-weight: 600;
  padding-right: 3px;
}

.price-head {
  background-image: url(../../assets/img/pricing/1.png);
  background-position: center;
  background-repeat: no-repeat;
}

.price-head h2 {
  font-size: 60px;
  font-weight: 700;
  display: inline-block;
}

@media only screen and (max-width: 479.98px) {
  .price-head h2 {
    font-size: 36px;
  }
}

.price-head h3 {
  display: inline-block;
}

.pricing-title {
  color: #F7941D;
  font-size: 30px;
  display: block;
  padding-top: 15px;
}

.pricing-list {
  margin-bottom: 20px;
  padding: 30px;
}

.pricing-list li {
  padding: 10px 0;
  border-bottom: 1px solid #e5e5e5;
}

.pricing-list li:first-child {
  border-top: 1px solid #e5e5e5;
}

/*------- pricing table end -------*/
/*------ pagination area style start ------*/
.paginatoin-area {
  padding: 20px;
  border: 1px solid #e5e5e5;
}

.paginatoin-area .pagination-box {
  display: flex;
  justify-content: center;
}

.paginatoin-area .pagination-box li {
  margin-right: 5px;
  display: inline-block;
}

.paginatoin-area .pagination-box li:last-child {
  margin-right: 0;
}

.paginatoin-area .pagination-box li a {
  color: #222222;
  height: 36px;
  width: 36px;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  line-height: 36px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.paginatoin-area .pagination-box li a i {
  font-size: 22px;
  line-height: 36px;
}

.paginatoin-area .pagination-box li a:hover {
  color: #fff;
  border-color: #F7941D;
  background-color: #F7941D;
}

.paginatoin-area .pagination-box li.active a {
  color: #fff;
  background-color: #F7941D;
}

/*------ pagination area style end ------*/
/*------- blog area start -------*/
.blog-post-item {
  overflow: hidden;
}

.blog-post-item:hover .blog-thumb img {
  transform: scale(1.1);
}

.blog-post-item:hover .blog-thumb.details-thumb img {
  transform: scale(1);
}

.blog-thumb {
  overflow: hidden;
}

.blog-thumb img {
  width: 100%;
}

.blog-content {
  padding: 32px 25px 35px;
  border: 1px solid #e5e5e5;
  border-top: none;
  border-radius: 0 0 5px 5px;
}

@media only screen and (max-width: 575.98px) {
  .blog-content {
    padding: 32px 15px 35px;
  }
}

.blog-content .blog-meta {
  margin-bottom: 6px;
}

.blog-content .blog-meta li {
  color: #444444;
  font-size: 14px;
  display: inline-block;
  margin-right: 20px;
  padding-right: 20px;
  position: relative;
}

.blog-content .blog-meta li:before {
  height: 15px;
  width: 1px;
  content: '';
  background-color: #e5e5e5;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.blog-content .blog-meta li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.blog-content .blog-meta li:last-child:before {
  display: none;
}

.blog-content .blog-title {
  line-height: 1.5;
}

.blog-content .blog-title a {
  color: #222222;
}

.blog-content .blog-title a:hover {
  color: #F7941D;
}

.blog-content .blog-group-btn {
  margin-top: 30px;
}

.blog-content .blog-group-btn a {
  color: #222222;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  font-family: 16px;
}

.blog-content .blog-group-btn a:hover {
  color: #F7941D;
}

.blog-content .blog-group-btn a i {
  padding-right: 8px;
}

.blog-content blockquote {
  border-left: 5px solid #F7941D;
  margin: 25px 32px 25px 30px;
  background-color: #f8f8f8;
  padding: 15px;
}

@media only screen and (max-width: 575.98px) {
  .blog-content blockquote {
    margin: 25px 15px 25px 15px;
  }
}

.blog-content.blog-details .blog-meta {
  margin-bottom: 20px;
}

.blog-content.blog-details .blog-title {
  line-height: 1.3;
}

@media only screen and (max-width: 575.98px) {
  .blog-content.blog-details .blog-title {
    font-size: 18px;
  }
}

/*------- blog area end -------*/
/*----- blog sidebar start -------*/
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .blog-widget-wrapper {
    margin-top: 108px;
  }
}

@media only screen and (max-width: 767.98px) {
  .blog-widget-wrapper {
    margin-top: 62px;
  }
}

.blog-widget {
  margin-bottom: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .blog-widget:last-child {
    margin-bottom: 0;
  }
}

.blog-widget .blog-author-title {
  padding: 20px;
  border: 1px solid #e5e5e5;
}

.blog-widget .blog-author-title span {
  display: block;
  font-size: 14px;
  padding: 8px 0;
}

.blog-widget .blog-author-title .blog-widget-icon a {
  color: #555555;
  font-size: 16px;
  margin-right: 15px;
}

.blog-widget .blog-author-title .blog-widget-icon a:hover {
  color: #F7941D;
}

.blog-widget .blog-author-title .blog-widget-icon a:last-child {
  margin-right: 0;
}

.blog-widget .blog-widget-img img {
  width: 100%;
}

.blog-widget-title {
  font-weight: 500;
  line-height: 1;
  margin-bottom: 40px;
  position: relative;
}

.blog-widget-title:before, .blog-widget-title:after {
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 1px;
  content: "";
  position: absolute;
  background-color: #e5e5e5;
}

.blog-widget-title:after {
  width: 50px;
  background-color: #222222;
}

.widget-search-form {
  position: relative;
}

.widget-search-form .search-field {
  width: 100%;
  border: none;
  height: 46px;
  line-height: 1;
  font-size: 14px;
  padding: 0 15px;
  padding-right: 55px;
  background-color: #f6f6f6;
}

.widget-search-form .search-btn {
  width: 46px;
  height: 46px;
  background-color: #F7941D;
  color: #fff;
  font-size: 18px;
  line-height: 46px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
}

.widget-search-form .search-btn:hover {
  background-color: #222222;
}

.recent-posts {
  display: flex;
  margin-bottom: 30px;
}

.recent-posts-image {
  width: 80px;
}

.recent-posts-body {
  line-height: 1;
  padding-left: 10px;
  width: calc(100% - 80px);
}

.recent-posts-meta {
  display: block;
  font-size: 14px;
  line-height: 1;
  padding-bottom: 10px;
}

.recent-posts-title {
  font-weight: 500;
  line-height: 1.3;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .recent-posts-title {
    font-size: 14px;
  }
}

.recent-posts-title a {
  color: #222222;
}

.recent-posts-title a:hover {
  color: #F7941D;
}

.recent-posts:last-child {
  margin-bottom: 0;
}

.blog-categories li {
  font-size: 16px;
  line-height: 1;
  margin-bottom: 20px;
}

.blog-categories li a {
  color: #222222;
}

.blog-categories li span {
  float: right;
  transition: 0.4s;
}

.blog-categories li:last-child {
  margin-bottom: 0;
}

.blog-categories li:hover a {
  color: #F7941D;
}

.blog-categories li:hover span {
  color: #F7941D;
}

.blog-tag {
  margin-bottom: -5px;
}

.blog-tag a {
  color: #222222;
  font-size: 14px;
  border: 1px solid #e5e5e5;
  line-height: 1;
  padding: 8px 15px;
  display: inline-block;
  margin-bottom: 5px;
}

.blog-tag a:hover {
  color: #fff;
  background-color: #F7941D;
  border-color: #F7941D;
}

/*----- blog sidebar end -------*/
/*--- blog comment section start ---*/
.comment-section {
  margin-top: -3px;
}

.comment-section h5 {
  line-height: 1;
  padding-bottom: 15px;
}

.comment-section ul li {
  display: flex;
  padding: 10px 20px;
  margin-bottom: 30px;
  border: 1px solid #e5e5e5;
}

.comment-section ul li:last-child {
  margin-bottom: 5px;
}

@media only screen and (max-width: 479.98px) {
  .comment-section ul li {
    display: block;
  }
}

.comment-section ul li .author-avatar {
  flex-basis: 66px;
  max-height: 62px;
  margin-right: 10px;
}

@media only screen and (max-width: 479.98px) {
  .comment-section ul li .author-avatar {
    flex-basis: 100%;
    max-width: 66px;
    height: 62px;
  }
}

.comment-section ul li.comment-children {
  margin-left: 40px;
}

@media only screen and (max-width: 479.98px) {
  .comment-section ul li.comment-children {
    margin-left: 20px;
  }
}

.comment-section ul li .comment-body {
  flex-basis: 100%;
}

.comment-section ul li .comment-body h5 {
  font-size: 14px;
  padding-bottom: 5px;
  font-family: "Open Sans", sans-serif;
}

.comment-section ul li .comment-body .comment-post-date {
  font-size: 13px;
  padding-bottom: 10px;
}

.comment-section ul li .comment-body .reply-btn {
  float: right;
}

.comment-section ul li .comment-body .reply-btn a {
  color: #222222;
  font-size: 12px;
  display: inline-block;
  padding: 4px 15px;
  border-radius: 20px;
  text-transform: capitalize;
  border: 1px solid #e5e5e5;
}

.comment-section ul li .comment-body .reply-btn a:hover {
  color: #fff;
  border-color: #F7941D;
  background-color: #F7941D;
}

/*--- blog comment section end ---*/
/*------ blog comment box start -----*/
.blog-comment-wrapper {
  margin-top: -10px;
}

.blog-comment-wrapper h3 {
  color: #222222;
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  padding-bottom: 15px;
  text-transform: uppercase;
  border-bottom: 1px solid #f0f0f0;
}

.blog-comment-wrapper p {
  margin-bottom: 10px;
  padding-top: 10px;
}

.blog-comment-wrapper .comment-post-box label {
  color: #222222;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
  text-transform: capitalize;
}

.blog-comment-wrapper .comment-post-box .coment-field {
  background: #f8f8f8;
  border: none;
  color: #555555;
  padding: 8px 10px;
  width: 100%;
}

@media only screen and (max-width: 767.98px) {
  .blog-comment-wrapper .comment-post-box .coment-field {
    margin-bottom: 20px;
  }
}

.blog-comment-wrapper .comment-post-box textarea {
  height: 130px;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
  background: #f8f8f8;
  border: none;
}

.blog-comment-wrapper .comment-post-box .coment-btn {
  margin-top: 30px;
}

@media only screen and (max-width: 767.98px) {
  .blog-comment-wrapper .comment-post-box .coment-btn {
    margin-top: 10px;
  }
}

/*------- blog comment box end --------*/
/*----- Google map area start -----*/
#google-map {
  height: 500px;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #google-map {
    height: 400px;
  }
}

@media only screen and (max-width: 767.98px) {
  #google-map {
    height: 350px;
  }
}

/*----- Google map area end -----*/
/*------ contact form area start -------*/
@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .contact-area {
    margin-bottom: -8px;
  }
}

.contact-message h2 {
  color: #222222;
  font-size: 22px;
  font-weight: 500;
  line-height: 1;
  padding-bottom: 28px;
  text-transform: capitalize;
}

@media only screen and (max-width: 575.98px) {
  .contact-message h2 {
    font-size: 18px;
    padding-bottom: 15px;
  }
}

.contact-message form input, .contact-message form textarea {
  width: 100%;
  border: none;
  padding: 10px 10px;
  border-bottom: 3px solid transparent;
  background-color: #f7f7f7;
  margin-bottom: 30px;
}

.contact-message form textarea {
  height: 150px;
}

/*------ contact form area start -------*/
/*------ contact info area start -------*/
.contact-information {
  height: 100%;
  padding-left: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .contact-information {
    margin-top: 62px;
    padding-left: 0;
  }
}

.contact-information p {
  padding-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .contact-information p {
    padding-bottom: 10px;
  }
}

.contact-information ul li {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 479.98px) {
  .contact-information ul li {
    align-items: baseline;
  }
}

.contact-information ul li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.contact-information ul li i {
  font-size: 18px;
  padding-right: 10px;
}

.contact-title {
  line-height: 1;
  margin-top: -4px;
  padding-bottom: 22px;
}

@media only screen and (max-width: 575.98px) {
  .contact-title {
    font-size: 20px;
    padding-bottom: 8px;
  }
}

/*------ contact info area end -------*/
/*----- footer section start -----*/
.footer-widget-area {
  margin-bottom: -5px;
}

.widget-title {
  font-weight: 700;
  margin: -6px 0 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .widget-title {
    margin: -6px 0 20px;
  }
}

.widget-body .desc {
  font-size: 14px;
  color: #222222;
}

.contact-info {
  padding-top: 12px;
}

.contact-info li {
  font-size: 14px;
  color: #222222;
  line-height: 1;
  margin-bottom: 13px;
}

.contact-info li a {
  color: #222222;
}

.contact-info li a:hover {
  color: #F7941D;
  padding-left: 10px;
}

.useful-links li {
  line-height: 1;
  margin-bottom: 15px;
}

.useful-links li a {
  color: #222222;
  font-size: 13px;
}

.useful-links li a:hover {
  color: #F7941D;
  padding-left: 10px;
}

.useful-links li:last-child {
  margin-bottom: 0;
}

.newsletter-inner {
  margin-top: 15px;
  margin-bottom: 14px;
}

.newsletter-inner form {
  position: relative;
}

.newsletter-inner .news-field {
  font-size: 14px;
  width: 100%;
  height: 44px;
  display: block;
  border: none;
  line-height: 1;
  color: #222222;
  background-color: #e5e5e5;
  position: relative;
  padding: 5px 55px 5px 10px;
}

.newsletter-inner .news-btn {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 18px;
  height: 44px;
  color: #fff;
  line-height: 1;
  font-weight: 400;
  padding: 0 15px;
  display: inline-block;
  background-color: #F7941D;
}

.newsletter-inner .news-btn:hover {
  background-color: #222222;
}

.widget-social-link a {
  color: #222222;
  font-size: 16px;
  display: inline-block;
  line-height: 1;
  text-align: center;
  margin-right: 15px;
}

.widget-social-link a:hover {
  color: #F7941D;
}

.widget-social-link a:last-child {
  margin-right: 0;
}

.footer-bottom {
  background-color: #dadada;
  padding: 14px 0;
}

.copyright-text p {
  font-size: 14px;
  color: #222222;
}

.copyright-text p span {
  color: #F7941D;
}

/*----- footer section end -----*/
