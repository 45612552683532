/*------- header top style start -------*/
.header-top {
    padding: 15px 0;
}
.header-top-left {
    li {
        color: $white;
        display: inline-block;
        margin-right: 25px;
        padding-right: 25px;
        position: relative;
        &:before {
            top: 50%;
            right: 0;
            width: 1px;
            height: 100%;
            content: '';
            position: absolute;
            background-color: #083281;
            transform: translateY(-50%);
        }
        a {
            color: $white;
            font-size: 15px;
            padding-left: 15px;
            &:hover {
                color: $theme-color;
            }
        }
        &:last-child {
            &:before {
                display: none;
            }
        }
    }
}

// header social link
.header-social-link {
    a {
        color: $white;
        margin-left: 22px;
        &:hover {
            color: $theme-color;
        }
        &:first-child {
            margin-left: 0;
        }
    }
}
/*------- header top style end -------*/

/*------ main menu start ------*/
.main-menu {
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        li {
            position: relative;
            a {
                color: $black-soft;
                font-size: 13px;
                padding: 29px 15px;
                font-weight: 500;
                display: block;
                text-transform: uppercase;
                font-family: $heading-font;
                i {
                    font-size: 14px;
                    padding: 0 3px;
                }
            }
            &:last-child {
                a {
                    padding-right: 0;
                }
            }

            &:hover>,
            &.active> {
                a {
                    color: $theme-color;
                }
            }

            ul {

                // dropdown menu
                &.dropdown {
                    top: 100%;
                    left: 0;
                    width: 220px;
                    position: absolute;
                    transition: 0.4s;
                    background-color: $white;
                    opacity: 0;
                    visibility: hidden;
                    z-index: 99999;
                    pointer-events: none;
                    transform: translateY(20px);
                    border: 1px solid #efefef;

                    li {
                        margin-right: 0;
                        border-right: none;
                        flex-basis: 100%;

                        a {
                            color: $paragraph;
                            font-size: 14px;
                            font-weight: 400;
                            padding: 10px 20px;
                            text-transform: capitalize;
                            position: relative;
                            border-bottom: 1px solid $bdr-color;
                            z-index: 1;

                            i {
                                float: right;
                                padding-top: 5px;
                            }
                        }

                        &:hover > {
                            a {
                                color: $white;
                                background-color: $theme-color;
                            }
                            ul {
                                &.dropdown {
                                    top: 0;
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }

                        ul {
                            &.dropdown {
                                top: 100%;
                                left: 100%;
                                opacity: 0;
                                visibility: hidden;
                            }
                        }
                    }
                }
            }

            &:hover {
                ul {
                    &.dropdown {
                        opacity: 1;
                        visibility: visible;
                        pointer-events: visible;
                        transform: translate(0);
                    }
                }
            }
        }
    }
}
/*------ main menu end ------*/

/*------ sticky menu style start ------*/
.sticky {
    &.is-sticky {
        top: 0;
        left: 0;
        position: fixed;
        width: 100%;
        z-index: 9;
        background-color: $white;
        box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
        animation: 900ms cubic-bezier(0.2, 1, 0.22, 1) 0s normal none 1 running fadeInDown;
    }
}

/*------ sticky menu style end ------*/