/*------ section title start ------*/
.section-title {
    margin-top: -12px;
    margin-bottom: 52px;
    // responsive
    @media #{$sm-device} {
        margin-bottom: 35px;
    }
    .title {
        font-size: 48px;
        position: relative;
        // responsive
        @media #{$md-device} {
            font-size: 36px;
        }
        @media #{$sm-device} {
            font-size: 24px;
            padding-top: 5px;
        }
        &:before {
            bottom: -36px;
            left: 0;
            right: 0;
            margin: 0 auto;
            content: url(../../assets/img/icon/shape.png);
            position: absolute;
        }
    }

    .sub-title {
        padding-top: 36px;
        margin: 0 auto;
        max-width: 55%;
        // responsive
        @media #{$sm-device} {
            max-width: 71%;
        }
        @media #{$xs-device} {
            max-width: 86%;
        }
    }
}

.section-title-append {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: -4px;
    margin-bottom: 18px;
    line-height: 1;

    &:after {
        top: 50%;
        right: 0;
        width: 100%;
        height: 1px;
        content: "";
        position: absolute;
        background-color: $bdr-color;
        transform: translateY(-50%);
        z-index: 1;
        margin-top: -3px;

        // responsive
        @media #{$md-device} {
            margin-top: 2px;
        }
    }

    h4 {
        display: inline-block;
        line-height: 1;
        text-transform: capitalize;
        background-color: $white;
        position: relative;
        z-index: 2;
        padding-right: 5px;

        // responsive
        @media #{$lg-device} {
            font-size: 16px;
        }
    }
}

/*------ section title end ------*/