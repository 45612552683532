/*------ hero slider area css start ------*/
/*-------------------------
02. Slider area
--------------------------*/
.hero-slider-item {
    height: 800px;
    display: flex;
    align-items: center;
    // responsive
    @media #{$xlmax-device} {
        height: 650px;
    }
    @media #{$lg-device, $xlmid-device} {
        height: 500px;
    }
    @media #{$md-device, $sm-device} {
        height: 400px;
    }
    @media #{$xs-device} {
        height: 350px;
    }
}
.hero-slider-content {
    .slide {
        &-title{
            color: $white;
            font-size: 50px;
            line-height: 1.2;
            font-family: $heading-font;
            span {
                display: block;
            }
            // responsive
            @media #{$md-device} {
                font-size: 35px;
            }
            @media #{$sm-device} {
                font-size: 30px;
            }
            @media #{$xxs-device} {
                font-size: 25px;
            }
        }  
        &-desc {
            color: $white;
            font-weight: 500;
            line-height: 1.3;
            padding-bottom: 16px;
        }
    }
    a {
        margin-top: 32px;
    }
}

.hero-overlay {
    position: relative; 
    &:before {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        background-color: rgba($black, 0.5);
        display: none;
        // responsive
        @media #{$sm-device} {
            display: block;
        }
    }
}
/*------ hero slider area css end ------*/

/*----- hero video style start -----*/
.hero-video {
    padding-bottom: 43% !important;
    // responsive
    @media #{$sm-device} {
        padding-bottom:70% !important;
    }
    @media #{$xs-device} {
        padding-bottom: 70% !important;
    }
    @media #{$xxs-device} {
        padding-bottom: 100% !important;
    }
}
.hero-slider {
    position: relative;
}
.hero-video-content-wrapper {
    position: absolute;
    background-color: rgba(0,0,0,0.7);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
/*----- hero video style end -----*/

